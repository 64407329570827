@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #000;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: bold;
  color: #009944;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  width: 100%;
}

#header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 10px;
}

@media only screen and (max-width: 640px) {
  #header .inner {
    padding: 65px 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .inner {
    padding: 0;
    justify-content: flex-end;
  }
}

#header #siteID {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    padding: 10px 0 !important;
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header #siteID {
    width: 35%;
    margin-left: 50px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #siteID {
    width: 30%;
  }
}

#header #siteID a {
  opacity: 1;
}

#header .tel2 {
  font-size: 26px;
  color: #fff;
  background: #89c997;
  padding: 15px;
  margin-right: 15px;
}

#header .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header .tel2 i {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .tel2 span {
    display: none;
  }
  #header .tel2 a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .tel2 {
    font-size: 20px;
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .tel2 {
    font-size: 18px;
  }
}

#hed_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 52%;
}

@media only screen and (max-width: 640px) {
  #hed_info {
    width: 100%;
    position: fixed;
    top: -1px;
    right: -15px;
    justify-content: flex-end;
    z-index: 2;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #hed_info {
    width: 60%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #hed_info {
    width: 65%;
  }
}

#hed_info .time {
  font-size: 14px;
  text-align: left;
  margin-right: 15px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .time {
    text-align: right;
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #hed_info .time {
    margin-right: 0;
  }
}

#hed_info .cont {
  margin-right: 10px;
}

@media only screen and (max-width: 834px) {
  #hed_info .cont {
    margin-right: 7px;
  }
}

#hed_info .cont i {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #hed_info .cont i {
    margin-right: 0;
  }
}

#hed_info .cont .pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  #hed_info .cont .pcnone {
    display: block;
  }
}

#hed_info .cont a {
  padding: 15px;
  display: block;
  background: #89c997;
  font-size: 20px;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #hed_info .cont a span {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .cont a span {
    display: none;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #hed_info .cont a {
    font-size: 18px;
  }
}

#hed_info .hed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hed_info .hed_cont {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .hed_cont {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global {
    padding: 70px 0;
    background: #89c997;
  }
}

#nav_global .inner {
  padding: 10px 0;
}

#nav_global .pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  #nav_global .pcnone {
    display: block;
  }
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: left;
  }
}

#nav_global ul li a {
  padding: 15px 0;
  color: #333;
  display: block;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li a {
    color: #fff;
    text-align: center;
  }
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

#mainArea {
  width: 100%;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

@media only screen and (max-width: 640px) {
  #mainArea img {
    width: 120% !important;
    min-height: 60vw !important;
    margin: 0 -20% !important;
    object-fit: cover;
  }
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  bottom: 10%;
  right: 5%;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    top: 60%;
    left: 10%;
    width: 80% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    width: 50%;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  text-align: center;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 150px 0;
  background: rgba(0, 0, 0, 0.3);
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: #b94047 !important;
  position: fixed !important;
  z-index: 8 !important;
}

.mean-container .mean-nav {
  background: #b94047 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.drawer-hamburger {
  z-index: 101 !important;
}

.drawer-hamburger span {
  background: #89c997 !important;
}

.drawer-hamburger span:before, .drawer-hamburger span:after {
  background: #89c997 !important;
}

.drawer-hamburger.change-color span {
  background: #2e4121 !important;
}

.drawer-hamburger.change-color span:before, .drawer-hamburger.change-color span:after {
  background: #2e4121 !important;
}

.drawer-nav {
  z-index: 101 !important;
  background: url(../images/shiba/green_bg.png);
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 20px;
}

.drawer-nav ul li:after {
  content: "";
  width: 32px;
  height: 1px;
  display: block;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
}

.drawer-hamburger {
  z-index: 201 !important;
}

.drawer-overlay {
  z-index: 201 !important;
}

.drawer-nav {
  z-index: 202 !important;
}

.drawer-open .drawer-hamburger .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .for-pc {
    display: none !important;
  }
}

.not-for-pc {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .not-for-pc {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .not-for-sp {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer #nav_footer {
  margin: 50px 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer ul:last-child {
  margin-top: 20px;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 16px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.address {
  margin-top: 30px;
}

#footer {
  padding-top: 50px;
}

#footer2 {
  background: url(../images/common/cont_bg.jpg) no-repeat;
  background-size: cover;
  padding: 100px 0;
}

#footer2 .ft_cont {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 640px) {
  #footer2 .ft_cont {
    display: block;
  }
}

#footer2 .ft_cont .tel2 {
  font-size: 26px;
  color: #fff;
  border: 1px solid #fff;
  padding: 15px;
}

@media only screen and (max-width: 834px) {
  #footer2 .ft_cont .tel2 {
    margin: 10px;
  }
  #footer2 .ft_cont .tel2 a {
    color: #fff;
  }
}

#footer2 .ft_cont .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #footer2 .ft_cont .mail {
    margin: 10px;
  }
}

#footer2 .ft_cont .mail a {
  font-size: 20px;
  color: #fff;
  border: 1px solid #fff;
  padding: 15px;
  display: block;
}

#footer2 h2 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 50px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* box */
.h2_01 h2 {
  font-size: 30px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 20px;
  }
}

.h2_01 h2 span {
  font-size: 40px;
  color: #009944;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_01 h2 span {
    font-size: 24px;
  }
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 span {
    display: block;
    font-size: 24px;
    line-height: 2;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .h2_01 h2 span {
    display: block;
    line-height: 2;
  }
}

.h2_02 h2 {
  font-size: 30px;
  border-bottom: 2px solid #ffdc00;
  padding-bottom: 10px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #009944;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 20px;
  }
}

.h2_03 h2 {
  font-size: 30px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #009944;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 20px;
  }
}

.h3_01 h3 {
  font-size: 20px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media only screen and (max-width: 834px) {
  .h3_01 h3 {
    font-size: 18px;
  }
}

.h3_02 h3 {
  font-size: 20px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  background: #89c997;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 834px) {
  .h3_02 h3 {
    font-size: 18px;
  }
}

.btn {
  width: 330px;
  margin: 10px auto;
}

@media only screen and (max-width: 640px) {
  .btn {
    width: 100%;
  }
}

.btn a {
  font-size: 20px;
  padding: 15px;
  display: block;
  background: #89c997;
  color: #fff;
}

.btn i {
  padding-left: 7px;
}

.sec_01 {
  position: relative;
}

.sec_01 .txt {
  line-height: 2;
}

.sec_01 .txt br {
  display: none;
}

.sec_01 .txt br:nth-child(3) {
  display: block;
}

.sec_01 .txt br:nth-child(5) {
  display: block;
}

.sec_01 .txt br:nth-child(7) {
  display: block;
}

@media only screen and (max-width: 834px) {
  .sec_01 .txt br {
    display: block;
  }
}

.sec_01 .img1 {
  position: absolute;
  top: 7%;
  left: 4%;
}

.sec_01 .img2 {
  position: absolute;
  bottom: 16%;
  left: 2%;
}

@media only screen and (max-width: 640px) {
  .sec_01 .img2 {
    bottom: 5%;
  }
}

.sec_01 .img3 {
  position: absolute;
  top: 26%;
  left: -10%;
}

@media only screen and (max-width: 834px) {
  .sec_01 .img3 {
    display: none;
  }
}

.sec_01 .img4 {
  position: absolute;
  left: -18%;
  bottom: 23%;
}

@media only screen and (max-width: 834px) {
  .sec_01 .img4 {
    display: none;
  }
}

.sec_01 .img5 {
  position: absolute;
  top: 24%;
  right: 0;
}

@media only screen and (max-width: 640px) {
  .sec_01 .img5 {
    top: 5%;
  }
}

.sec_01 .img6 {
  position: absolute;
  top: 8%;
  right: -18%;
}

@media only screen and (max-width: 834px) {
  .sec_01 .img6 {
    display: none;
  }
}

.sec_01 .img7 {
  position: absolute;
  right: -14%;
  bottom: 38%;
}

@media only screen and (max-width: 834px) {
  .sec_01 .img7 {
    display: none;
  }
}

.sec_01 .img8 {
  position: absolute;
  right: 4%;
  bottom: 14%;
}

@media only screen and (max-width: 640px) {
  .sec_01 .img8 {
    bottom: 5%;
  }
}

.bnrbox {
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
}

.bnrbox .box {
  border-radius: 15px;
  text-align: center;
}

.bnrbox .box img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .bnrbox .box {
    margin: 10px;
    width: 46%;
  }
}

.bnrbox .box a {
  display: block;
}

.bnrbox .box a:hover {
  transform: scale(1.3);
  max-width: 100%;
  vertical-align: top;
  -webkit-transition: all 1.8s ease-out;
  transition: all 1.8s ease-out;
  width: 100%;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .bnrbox .box {
    width: 22%;
    margin: 10px;
  }
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  width: 48%;
}

.sec_02 .flxR {
  width: 48%;
}

.sec_03 {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .sec_03 .flxR {
    width: 100% !important;
  }
}

.sec_03 .flxL {
  width: 48%;
}

.sec_03 .flxR {
  width: 48%;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
}

.sec_04 .bnr {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .bnr {
    width: 100% !important;
  }
}

.sec_04 .bnr a {
  display: block;
}

.sec_04 > div:first-child {
  display: none;
}

.sec_04 > div:last-child {
  display: none;
}

.newswrap {
  padding: 20px 0;
}

.news {
  height: 270px;
  overflow-y: scroll;
}

.news dt {
  display: block !important;
  border: none !important;
  width: 100% !important;
  padding: 0 !important;
}

.news dd {
  border: none !important;
  width: 100% !important;
  padding: 0 !important;
}

.news dl {
  display: block !important;
  padding: 10px 0 !important;
  border-bottom: 1px dashed #bee0ce;
}

#feed {
  height: 270px;
  overflow-y: scroll;
}

.ameblo {
  padding: 20px 0;
}

.ameblo dt {
  padding-top: 10px;
}

.ameblo dd {
  border-bottom: 1px dashed #bee0ce;
  padding-bottom: 10px;
}

.ameblo dd a {
  color: #333;
}

.tbl_block {
  margin: 0 !important;
  padding: 10px;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.h2_04 h2 {
  font-size: 30px;
  font-family: tbudrgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  border-bottom: 2px solid #bee0ce;
  color: #009944;
  padding-bottom: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 20px;
  }
}

.btn2 {
  width: 330px;
  margin: 10px auto;
}

.btn2 a {
  font-size: 20px;
  padding: 15px;
  display: block;
  background: #da81b2;
  color: #fff;
}

.btn2 i {
  padding-left: 7px;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
  background: #ffffe5;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .txt {
    width: 100% !important;
  }
  .sec_05 .img {
    width: 100% !important;
  }
}

.sec_05 .txt {
  width: 68%;
  padding: 10px;
}

.sec_05 .txt b {
  color: #009944;
}

.sec_05 .img {
  width: 28%;
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  margin: 50px 0;
  background: #ffffe5;
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .txt {
    width: 100% !important;
  }
  .sec_06 .img {
    width: 100% !important;
  }
}

.sec_06 .txt {
  width: 68%;
  padding: 10px;
}

.sec_06 .txt b {
  color: #009944;
}

.sec_06 .img {
  width: 28%;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .txt {
    width: 100% !important;
  }
  .sec_07 .img {
    width: 100% !important;
  }
}

.sec_07 .txt {
  width: 68%;
  font-size: 18px;
}

.sec_07 .txt b {
  font-size: 26px;
  margin-right: 7px;
}

.sec_07 .img {
  width: 28%;
}

.sec_08 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_08 {
    display: block;
  }
  .sec_08 .table_03 {
    width: 100% !important;
  }
  .sec_08 .img {
    width: 100% !important;
  }
}

.sec_08 .table_03 {
  width: 58%;
}

.sec_08 .table_03 dt {
  border: none !important;
  border-bottom: 1px solid #a9cf52 !important;
}

.sec_08 .table_03 dd {
  border: none !important;
  border-bottom: 1px solid #2a4198 !important;
}

.sec_08 .img {
  width: 38%;
}

.sec_09 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_09 {
    display: block;
  }
  .sec_09 .flxL {
    width: 100% !important;
  }
  .sec_09 .flxR {
    width: 100% !important;
  }
}

.sec_09 .flxL {
  width: 48%;
}

.sec_09 .flxL dt {
  border: none !important;
  border-bottom: 1px solid #a9cf52 !important;
}

.sec_09 .flxL dd {
  border: none !important;
  border-bottom: 1px solid #2a4198 !important;
}

.sec_09 .flxR {
  width: 48%;
}

.sec_09 .flxR .txt {
  font-size: 18px;
}

.table_03 dt {
  border: none !important;
  border-bottom: 1px solid #a9cf52 !important;
}

.table_03 dd {
  border: none !important;
  border-bottom: 1px solid #2a4198 !important;
}

.gmap iframe {
  width: 100%;
}

.sec_08 > div:first-child {
  display: none;
}

.sec_08 > div:last-child {
  display: none;
}

.table_04 .td_top {
  font-size: 20px;
  padding: 23px !important;
}

@media only screen and (max-width: 640px) {
  .table_04 .td_top {
    font-size: 18px;
  }
}

.table_04 .tel2 {
  font-size: 26px;
}

@media only screen and (max-width: 640px) {
  .table_04 .tel2 a {
    color: #333;
  }
}

.btnwrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .btnwrap {
    display: block;
  }
}

.btnwrap .btn {
  width: 48%;
}

.sec_10 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_10 {
    display: block;
  }
  .sec_10 .flxL {
    width: 100% !important;
  }
  .sec_10 .flxR {
    width: 100% !important;
  }
}

.sec_10 .midashi {
  background: #409ecc;
  color: #fff;
  font-size: 20px;
  padding: 15px;
}

.sec_10 .flxL {
  width: 48%;
  border: 1px solid #bbdbf3;
}

.sec_10 .flxL .tel3 {
  padding: 10px;
}

.sec_10 .flxL .tel3 a {
  color: #333;
  font-size: 26px;
}

.sec_10 .flxL .tel2 {
  padding: 10px;
}

.sec_10 .flxL .tel2 a {
  color: #333;
  font-size: 26px;
}

.sec_10 .flxR {
  width: 48%;
  border: 1px solid #bbdbf3;
}

.sec_10 .flxR .mail {
  padding: 10px;
}

.sec_10 .flxR .mail a {
  color: #333;
}

.qa dl {
  border-bottom: 5px dotted #bee0ce;
  display: block !important;
  padding: 15px 0 !important;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #e62e8b;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #fed900;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dd {
  width: 100% !important;
  border: none !important;
}

.sptac .td_left {
  background: #ffffe0 !important;
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .sptac td {
    text-align: center !important;
  }
}

.bukken {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 640px) {
  .bukken {
    display: block;
  }
  .bukken .flxL {
    width: 100% !important;
  }
  .bukken .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bukken {
    display: block;
  }
  .bukken .flxL {
    width: 100% !important;
  }
  .bukken .flxR {
    width: 100% !important;
  }
}

.bukken .table_02 {
  font-size: 20px;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.bukken .table_02 .td_top {
  background: #ffffe0;
  font-weight: bold;
}

.bukken .table_02 td {
  border: none !important;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .bukken .table_02 {
    font-size: 16px;
  }
  .bukken .table_02 table {
    overflow: scroll;
    white-space: nowrap;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bukken .table_02 {
    font-size: 16px;
  }
}

.bukken .flxL {
  width: 28%;
}

.bukken .flxR {
  width: 70%;
}

.bukken_img {
  margin: 0 !important;
}

@media only screen and (max-width: 640px) {
  .bukken_img {
    text-align: center !important;
  }
  .bukken_img img {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bukken_img {
    text-align: center !important;
  }
  .bukken_img img {
    width: 100% !important;
  }
}

.policy p {
  padding: 20px 0;
}

.access {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 640px) {
  .access {
    display: block;
  }
}

.area {
  padding: 20px;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.area span {
  font-size: 24px;
}

@media only screen and (max-width: 640px) {
  .area span {
    font-size: 20px;
  }
}

.style_form th {
  background: #ffffe0;
  padding: 10px;
}

.style_form td {
  padding: 16px;
}

.midashi h2 {
  color: #fff;
  line-height: 2em;
  font-size: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 170px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 260px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 360px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #d6f2e3;
  z-index: 0;
  padding: 60px 0;
}

.bg_100per_wrap2 {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #009944;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  text-align: center;
  padding: 10px 0;
}
